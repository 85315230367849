.cube-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cube {
  position: relative;
  width: 250px;
  height: 250px;
  top: 50px;
  transform-style: preserve-3d;
  animation: animate 4s linear infinite;
  z-index: 2;
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-30deg) rotateY(360deg);
  }
}

.cube .box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.cube .box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: rgba(65, 44, 197, 1);
  transform-style: preserve-3d;
  transform: rotateY(calc(90deg * var(--i))) translateZ(125px); /* Ajusta translateZ según el tamaño del cubo */
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transform-style: preserve-3d;
  transform: rotateX(90deg) translateZ(125px); /* Ajusta translateZ según el tamaño del cubo */
  display: flex;
  justify-content: center;
  align-items: center;
}

.top .content {
  position: relative;
  font-size: 9em;
  font-weight: bold;
  font-family: 'Praktika Rounded Bold', sans-serif;
  color: rgba(65, 44, 197, 1);
  text-shadow: 0 0 5px rgba(65, 44, 197, 1), /* Reducido */
               0 0 10px rgba(65, 44, 197, 1), /* Reducido */
               0 0 15px rgba(65, 44, 197, 1), /* Reducido */
               0 0 20px rgba(65, 44, 197, 1); /* Reducido */
}

.top .content .plus {
  position: absolute;
  top: -10px;
  left: 80px;
  font-size: 0.6em;
  font-weight: bold;
  font-family: 'Praktika Rounded Bold', sans-serif;
  color: rgba(241, 90, 36, 1);
}

.top::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(65, 44, 197, 1);
  transform: translateZ(-125px); /* Ajusta según el tamaño del cubo */
  opacity: 0.5; /* Reducido */
  box-shadow: 0 0 30px rgba(65, 44, 197, 1), /* Reducido */
              0 0 50px rgba(65, 44, 197, 1), /* Reducido */
              0 0 75px rgba(65, 44, 197, 1); /* Reducido */
}
