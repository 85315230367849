.carousel-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.carousel-content {
  display: flex;
  animation: carousel-animation 30s linear infinite;
}

@keyframes carousel-animation {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.logo-container {
  width: 220px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
