@tailwind base;
@tailwind components;
@tailwind utilities;


/* General font settings */
body {
    color: #333;
}

/* Heading 1 */
h1 {
    font-weight: 700;
    font-size: 2.5rem;
    color: #222;
}

/* Heading 2 */
h2 {
    font-weight: 600;
    font-size: 2rem;
    color: #444;
}

/* Paragraph */
p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}
